var ExamType = {
    ChuZhen: 1,
    FuCha: 2,
    XinJing: 3,
    ShiPei: 4,
    HuanJing: 5,
    YouHua: 6,
    DingPian: 7,
    YanPian: 8,
    JiaoPian: 9
}
export default ExamType;